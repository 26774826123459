.pageHeader {
    padding-left: 10px;
    border-bottom: 1px solid #000;
}
.sponsorHeader {
    color: #fff !important;
    border-bottom: 0px !important;
    font-weight: 700 !important;
}
.accordion {
    width: 100%;
}
.accordion-button, .accordion-button:focus, .accordion-button:not(.collapsed) {
    border-radius: inherit;
    background-color: transparent;
    box-shadow: none;
}
.workshopGroup {
    width: 100%;
    margin-bottom: 20px;
    border: 1px solid #000 !important;
    border-radius: 20px !important;
    background-color: transparent;
}
.subHeader {
    align-self: flex-start;
    border-bottom: 1px solid #000;
    margin-bottom: 30px;
}
.trainImg {
    border: 1px solid #000;
    object-position: center;
    object-fit: cover;
    width: 100%;
    border-radius: 10px;
    margin-bottom: 20px;
}
.trainBody {
    font-size: clamp(10px, 13px, 18px);
    text-align: justify;
}
.txtCol {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}
.purpSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #9090EE;
    padding: 40px 25px;
}
.sponsors {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
}
.sponsorImg {
    width: fit-content;
    min-width: 5rem;
}
.workshopGroupHeader {
    border-bottom: 0px !important;
}
.testiBg {
    background-image: url('../../Images/mosaic-bg.png');
    max-width: 100%;
    padding: 3rem 0rem;
}


@media (min-width: 27rem) {
    .trainBody {
        font-size: 1rem;
    }
    .purpSection {
        max-width: 100%;
        padding: 4rem;
    }
    .sponsors {
        gap: 2rem;
    }
    .sponsorImg {
        min-width: 6rem;
    }
    .trainImg {
        max-width: 15rem;
        max-height: 20rem;
    }
    .donateCardBtn {
        padding: .5rem 1.5rem;
    }
    .testiBg {
        padding: 4rem 0rem;
    }
}
@media (min-width: 64rem) {
    .trainBody {
        font-size: 1.2rem;
    }
    .purpSection {
        max-width: 100%;
        padding: 5rem;
    }
    .sponsorImg {
        min-width: 8rem;
    }
    .trainImg {
        max-width: 20rem;
        max-height: 25rem;
    }
    .donateCardBtn {
        padding: .5rem 1.5rem;
    }
    .testiBg {
        padding: 5rem 0rem;
    }
}
@media (min-width: 90rem) {
    .purpSection {
        max-width: 100%;
        padding: 6rem;
    }
    .trainBody {
        font-size: 1.5rem;
    }
    .sponsorImg {
        min-width: 10rem;
    }
    .trainImg {
        max-width: 25rem;
        max-height: 30rem;
    }
    .donateCardBtn {
        padding: .5rem 1.5rem;
    }
    .testiBg {
        padding: 6rem 0rem;
    }
}