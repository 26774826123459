#aboutHero {
    background-image: url('../../Images/HERO.png') !important;
    background-size: cover;
    max-width: 100% !important;
}
.origins {
    background-color: #90EE90;
    padding: 50px 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.originsHeader {
    font-weight: 400 !important;
    border-bottom: 1px solid #000 !important;
    margin-bottom: 1.75rem;
}
.originsBody {
    font-weight: 400 !important;
}
.founder {
    background-color: #000;
    background-image: url('../../Images/founderBg.png');
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: cover;
    padding: 50px 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
.founderPic {
    border: 2px solid #9090EE;
    border-radius: 25px;
    width: 80%;
    max-height: 300px;
    object-fit: cover;
}
.founderHeader {
    color: #4EE44E !important;
    font-size: clamp(15px, 20px, 30px);
    font-weight: 300 !important;
    align-self: center !important;
    margin-bottom: 20px;
}
.founderHeaderBold {
    font-size: clamp(20px, 30px, 40px);
    font-weight: 600 !important;
}
.founderBody {
    color: #4EE44E !important;
    font-weight: 400 !important;
    font-size: clamp(10px, 13px, 25px);
}
.fitted {
    max-width: fit-content;
}
.eventImg {
    border: 1px solid #000;
    width: 100%;
    border-radius: 10px;
}
.eventHeader {
    font-size: clamp(15px, 20px, 30px) !important;
    font-weight: 300 !important;
    margin-bottom: 20px;
}
.eventHeaderBody {
    min-height: 75px;
    max-height: 125px;
    overflow: hidden;
}
.accordion-item {
    border: 0px;
}
.accordion-header {
    border-bottom: 1px solid #000;
}
.aboutHeader {
    width: 100%;
    margin-bottom: 20px;
    background-color: transparent;
    border-radius: 0px !important;
}
.aboutBody {
    background-color: #fff;
    border-left: 1px solid #000;
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
    border-radius: 0px 0px 20px 20px;
}

@media (min-width: 27rem) {
    .origins, .founder {
        max-width: 100%;
        margin: 0px;
        padding: 5rem;
    }
    #aboutHero {
        min-height: 20rem !important;
    }
    .originsBody {
        font-size: 1rem;
        margin-bottom: 5rem;
    }
    .accordion-button {
        font-size: 1.5rem;
    }
    .founderBody {
        font-size: 1rem;
    }
    .founderPic {
        min-height: 30rem;
    }
    .founderHeaderBold {
        font-size: 3rem;
    }
    .founderHeader {
        font-size: 1.5rem !important;
    }
    #eventHeader {
        font-size: 1.5rem !important;
        line-height: 2rem;
        margin-bottom: 2rem !important;
    }
    .fitted {
        min-width: fit-content;
        max-width: 30%;
    }
}
@media (min-width: 64rem) {
    .origins, .founder {
        max-width: 100%;
        margin: 0px;
        padding: 6.25rem;
    }
    #aboutHero {
        min-height: 40rem !important;
    }
    .originsBody {
        font-size: 1rem;
        margin-bottom: 5rem;
    }
    .accordion-button {
        font-size: 1.5rem;
    }
    .founderBody {
        font-size: 1.5rem;
    }
    .founderPic {
        min-height: 50rem;
    }
    .founderHeaderBold {
        font-size: 5rem;
    }
    .founderHeader {
        font-size: 2.5rem !important;
    }
    #eventHeader {
        font-size: 2.5rem !important;
        margin-bottom: 5rem !important;
    }
    .fitted {
        min-width: fit-content;
        max-width: 30%;
    }
}
@media (min-width: 90rem) {
    .origins, .founder {
        max-width: 100%;
        margin: 0px;
        padding: 6.25rem;
    }
    #aboutHero {
        min-height: 40rem !important;
    }
    .originsBody {
        font-size: 1rem;
        margin-bottom: 5rem;
    }
    .accordion-button {
        font-size: 1.5rem;
    }
    .founderBody {
        font-size: 1.5rem;
    }
    .founderPic {
        min-height: 50rem;
    }
    .founderHeaderBold {
        font-size: 5rem;
    }
    .founderHeader {
        font-size: 2.5rem;
    }
    #eventHeader {
        font-size: 2.5rem !important;
        margin-bottom: 5rem !important;
    }
    .fitted {
        min-width: fit-content;
        max-width: 30%;
    }
}