@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

.raleway {
    font-family: "Raleway", sans-serif;
    font-optical-sizing: auto;
}
.openSans {
    font-family: "Open Sans", sans-serif;
    font-optical-sizing: auto;
}
.asfs {
  align-self: flex-start;
}
.jcc {
  justify-content: center;
}
.jcfs {
  justify-content: flex-start;
}
.jcfe {
  justify-content: flex-end;
}
.jcse {
  justify-content: space-evenly;
}
.jcsb {
  justify-content: space-between;
}
.jcsa {
  justify-content: space-around;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
