@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

a {
    text-decoration: none;
}
.niahNavbar {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    background-color: #fff;
    padding: 50px 25px;
}
.niahNavbarContainer {
    flex-direction: row;
}
.niahLogo {
    max-width: 3rem;
}
.niahNavbarToggle {
    display: none;
}
.niahNavbarMenu {
    max-width: 50vw;
}
.navbox {
    width: max-content;
    display: flex;
    flex-direction: column;
}
.navbox1 {
    display: flex;
    flex-direction: row;
    gap: 1rem;
}
.navLink {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: start;
    text-wrap: nowrap;
    justify-content: center;
    font-family: "Open Sans", sans-serif;
    font-optical-sizing: auto;
    font-size: .8rem;
    text-transform: uppercase;
    font-weight: 500;
    font-style: normal;
    color: #000;
    min-height: 50px;
}
.niahNavbarBtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #000 !important;
    color: #fff !important;
    border: 2px solid #4EE44E !important;
    border-radius: 25px;
    padding: 6px 29px;
    max-width: 100%;
    font-family: "Open Sans", sans-serif;
    font-optical-sizing: auto;
    font-size: 1rem;
    font-weight: 400;
    font-style: normal;
    color: #000;
    text-transform: uppercase;
    margin-left: 1rem;
}
.smLink {
    font-family: "Open Sans", sans-serif;
    font-optical-sizing: auto;
    font-size: clamp(10px, 2.5vw, 20px);
    font-weight: 400;
    font-style: normal;
}

@media (max-width: 900px) {
    .niahNavbar {
        padding: 10px;
    }
    .niahLogo {
        max-width: 2rem;
    }
    .niahNavbarToggle {
        display: flex;
        background-color: #000 !important;
        color: #fff !important;
        border: 2px solid #4EE44E !important;
        border-radius: 5px;
        padding: 5px;
    }
    .navbox1 {
        display: none;
    }
    .navLink {
        font-size: 1.25rem;
    }
    .niahNavbarBtn {
        margin-top: 2.5rem;
        margin-left: 0rem;
    }
    .ghost {
        display: none;
    }
}