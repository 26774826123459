@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');


.w100 {
    max-width: 100%;
}
.homeHeroSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url('../../Images/pic1.png');
    background-position: center;
    background-size: cover;
    padding: 50px;
}
.homeHeroHeader {
    font-family: "Raleway", sans-serif;
    font-optical-sizing: auto;
    font-size: 4rem;
    font-weight: 800;
    font-style: normal;
    color: #fff;
    margin-top: 26%;
}
.homeHeroText {
    font-family: "Open Sans", sans-serif;
    font-optical-sizing: auto;
    font-size: 1rem;
    font-weight: 400;
    font-style: normal;
    color: #fff;
    width: 85%;
    margin-bottom: 2rem;
}
.homeHeroBtnBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 40px;
}
.homeHeroBtn {
    border: 1px solid #fff;
    border-radius: 10px;
    background-color: #000;
    color: #fff;
    padding: 7px 18px;
    font-family: "Open Sans", sans-serif;
    font-optical-sizing: auto;
    font-size: 1rem;
    font-weight: 400;
    font-style: normal;
    color: #fff;
}
.greenGradientSection {
    background-image: linear-gradient( white, #4EE44E, white);
    width: 100%;
    height: fit-content;
    padding: 50px 20px;
    display: flex;
    justify-content: center;
}
.col1, .col2, .col3 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 50%;
    padding-top: 2vw;
}
.col1 {
    background-image: url('../../Images/icn.png');
}
.col2 {
    background-image: url('../../Images/icn1.png');
}
.col3 {
    background-image: url('../../Images/icn2.png');
}
.gradientTxt {
    max-width: 22rem;
    font-family: "Raleway", sans-serif;
    font-optical-sizing: auto;
    font-size: clamp(8px, 2.5vw, 40px);
    font-weight: 400;
    line-height: 3rem;
    letter-spacing: 0.005em;
    font-style: normal;
    text-align: justify;
    color: #000;
}
.gradientTxtSub {
    font-weight: 600;
}
.gradientTxt1 {
    min-width: 6.25rem;
    margin-top: 1rem;
    font-family: "Raleway", sans-serif;
    font-optical-sizing: auto;
    font-size: 1.3rem;
    font-weight: 400;
    font-style: normal;
    color: #000;
}
.gradientTxt1sub {
    font-weight: 600;
}
.mosaicBg {
    background-image: url('../../Images/mosaic-bg.png');
    padding: 10px 20px;
}
.mosaicBg1 {
    background-image: url('../../Images/mosaic-bg.png');
    padding: 50px 45px;
}
.OFHeader {
    font-family: "Raleway", sans-serif;
    font-optical-sizing: auto;
    font-size: clamp(15px, 2.5vw, 30px);
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0.05em;
    font-style: normal;
    text-align: justify;
    color: #000;
}
.peachHeader {
    font-size: clamp(20px, 2.5vw, 40px);
    color: #EE9090;
}
.peachHeader1 {
    font-size: clamp(12px, 2.5vw, 24px);
    color: #EE9090;
    text-decoration: underline;
    text-align: start;
}
.OFDesc {
    width: 100%;
    font-family: "Open Sans", sans-serif;
    font-optical-sizing: auto;
    font-size: clamp(8px, 2.5vw, 16px);
    font-weight: 400;
    font-style: normal;
    color: #000;
    text-align: justify;
}
.boxDiv, .boxDiv1 {
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    border: 1px solid #000;
    border-radius: 10px;
    margin-bottom: 25px;
}
.boxDiv1 {
    margin-top: 25px;
}
.MissionImgBox {
    max-width: 100%;
    width: fit-content;
    border: 2px solid #EE9090;
    border-radius: 10px;
}
.MissionImgBox img {
    max-width: 100%;
}
.greenBg {
    background-image: url('../../Images/greenbg.png');
    padding: 25px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.greenBgPic {
    width: 100%;
    border: 2px solid #000;
    border-radius: 10px;
    margin-bottom: 10px;
}
.greenBgTxt, .greenBgHeader {
    font-family: "Raleway", sans-serif;
    font-optical-sizing: auto;
    font-size: clamp(12px, 2.5vw, 24px);
    font-weight: 300;
    line-height: 15px;
    letter-spacing: 0.05em;
    font-style: normal;
    text-align: justify;
    color: #000;
}
.greenBgHeader {
    font-size: clamp(20px, 2.5vw, 40px);
    font-weight: 400;
}
.greenBgTxt {
    width: 100%;
}
.greenBgRow {
    gap: 10px;
    max-width: 100%;
}
.eventHeader, .eventHeader1, .eventHeaderTxt, .eventHeaderBody {
    font-family: "Raleway", sans-serif;
    font-optical-sizing: auto;
    font-size: clamp(20px, 2.5vw, 24px);
    font-weight: 300;
    line-height: 15px;
    letter-spacing: 0.05em;
    font-style: normal;
    text-align: justify;
    color: #000;
}
.eventHeader, .eventHeader1 {
    border-bottom: 2px solid #EE9090;
    padding-right: 8px;
    padding-bottom: 5px;
    width: fit-content;
}
.eventHeader1 {
    border-bottom: 2px solid #000;
}
.eventHeaderTxt {
    font-size: clamp(10px, 2.5vw, 20px);
    font-weight: 300;
    letter-spacing: 0.01em;
    border-bottom: 1px solid #000;
}
.eventHeaderBody {    
    font-family: "Open Sans", sans-serif;
    font-size: clamp(7px, 2.5vw, 14px);
    font-weight: 300;
}
.eventPic {
    background-image: url('../../Images/Picture.png');
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    border: 1.5px solid #000;
    border-radius: 10px;
    margin-bottom: 10px;
}
.donateCard, .subscribeCard {
    padding: 35px 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 3px solid #000;
    border-radius: 20px;
    background-color: #EE9090;
    margin-top: 90px;
}
.subscribeCard {
    background-color: #9090EE;
}
.donateCardBtn {
    width: fit-content;
    padding: 5px 10px;
    background-color: #fff;
    color: #000;
    font-size: clamp(8px, 2.5vw, 16px);
    border-radius: 20px;
}
.donateCardHeader, .donateCardBody {
    font-family: "Raleway", sans-serif;
    font-optical-sizing: auto;
    line-height: 15px;
    letter-spacing: 0.05em;
    font-style: normal;
    text-align: justify;
    color: #fff;
}
.donateCardHeader {
    font-size: clamp(12px, 2.5vw, 24px);
    font-weight: 600;
    text-transform: uppercase;
    border-bottom: 1px solid #fff;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 2px;
    width: fit-content;
}
.donateCardBody {
    font-size: clamp(8px, 2.5vw, 16px);
    font-weight: 500;
    line-height: 10px;
    width: 100%;
}
.green {
    color: #4EE44E;
}
.greenBg1 {
    background-image: url('../../Images/greenBg1.png');
    padding: 50px 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.newEventImg {
    border: 1px solid #000;
    border-radius: 5px;
    background-image: url('../../Images/PictureK.png');
    background-size: cover;
    background-repeat: no-repeat;
    min-width: 90px;
    min-height: 120px;
    margin-bottom: 5px;
}
.newEventHeader, .newEventTxt {
    font-family: "Raleway", sans-serif;
    font-optical-sizing: auto;
    line-height: 10px;
    letter-spacing: 0.05em;
    font-style: normal;
    text-align: justify;
    color: #000;
}
.newEventHeader {
    font-size: clamp(10px, 2.5vw, 20px);
    font-weight: 500;
}
.newEventTxt {
    font-size: 1rem;
    font-weight: 300;
}
.header1 {
    font-family: "Raleway", sans-serif;
    font-optical-sizing: auto;
    font-size: clamp(15px, 2.5vw, 30px);
    font-weight: 700;
    line-height: 10px;
    letter-spacing: 0.05em;
    font-style: normal;
    text-align: justify;
    color: #000;
    width: fit-content;
    padding-bottom: 3px;
    border-bottom: 1px solid #000;
}
.body1 {
    font-family: "Raleway", sans-serif;
    font-optical-sizing: auto;
    font-size: clamp(8px, 2.5vw, 16px);
    font-weight: 300;
    line-height: 10px;
    letter-spacing: 0.05em;
    font-style: normal;
    text-align: justify;
    color: #000;
    width: fit-content;
    margin-top: 40px;
}
.purple {
    color: #9090EE;
}
.peach {
    color: #EE9090;
}
.newsletterForm {
    width: 100%;
}
.input {
    border-bottom: 2px solid #fff;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-radius: 0px;
    color: #fff;
    background-color: transparent;
    width: 100%;
    text-align: start;
    font-family: "Raleway", sans-serif;
    font-optical-sizing: auto;
    font-size: clamp(8px, 2.5vw, 16px);
    font-weight: 300;
}
.input:placeholder-shown {
    color: #fff;
}

@media (max-width: 26.9rem) {
    .homeHeroSection {
        padding: 20px;
    }
    .homeHeroHeader {
        font-size: 2rem;
    }
    .homeHeroText {
        font-size: .5rem;
        margin-bottom: 1rem;
    }
    .homeHeroBtn {
        font-size: .5rem;
    }
    .gradientTxt {
        min-width: 6.25rem;
        line-height: 1rem
    }
    .gradientTxt1 {
        font-size: 1rem;
    }
    .newEventImg {
        min-width: 100%;
    }
    .newEventTxt {
        font-size: .6rem;
        line-height: .8rem;
    }
    .body1 {
        margin-top: 1rem;
    }
    .subscribeCard, .donateCard {
        width: 100%;
    }
    .greenBg, .greenBg1, .mosaicBg, .mosaicBg1, .homeHeroSection, .greenGradientSection {
        padding: 1.25rem !important;
        align-items: center;
    }
}
@media (min-width: 27rem) {
    .homeHeroSection {
        padding: 20px;
    }
    .homeHeroHeader {
        font-size: 2rem;
    }
    .homeHeroText {
        font-size: .8rem;
        margin-bottom: 1rem;
    }
    .homeHeroBtn {
        font-size: .5rem;
    }
    .gradientTxt1 {
        font-size: 1rem;
    }
    .subscribeCard, .donateCard {
        width: 75%;
    }
    .body1 {
        font-size: 1rem;
        line-height: 1rem;
        margin-top: 2rem;
    }
    .newEventHeader {
        font-size: 1rem;
    }
    .newEventHeader, .newEventTxt {
        line-height: 1rem;
    }
    .newEventImg {
        min-width: 100%;
        min-height: 15rem;
    }
    .greenBgPic {
        min-width: 50%;
        min-height: 15rem;
    }
    .eventHeaderTxt {
        font-size: 1.2rem;
        line-height: 2rem;
    }
    .eventHeaderBody {
        min-height: 50%;
        line-height: 1rem;
    }
    .donateCardBody {
        font-size: 1rem;
        line-height: 1rem;
    }
    .greenBgPic {
        min-height: 20rem;
    }
    .greenBgTxt {
        font-size: 1.2rem;
        line-height: 1.4rem;
    }
    .greenBgHeader {
        font-size: 1.5rem;
        line-height: 1.6rem;
    }
    .gradientTxt {
        font-size: 1.2rem;
        line-height: 1.4rem;
    }
    .greenBg, .greenBg1, .mosaicBg, .mosaicBg1, .homeHeroSection, .greenGradientSection {
        padding: 3.125rem;
        align-items: center;
    }
}
@media (min-width: 64rem) {
    .homeHeroSection {
        padding: 20px;
    }
    .homeHeroHeader {
        font-size: 2rem;
    }
    .homeHeroText {
        font-size: .8rem;
        margin-bottom: 1rem;
    }
    .homeHeroBtn {
        font-size: .5rem;
    }
    .gradientTxt1 {
        font-size: 1rem;
    }
    .subscribeCard, .donateCard {
        max-width: 75%;
        min-width: 70%;
    }
    .body1 {
        font-size: 1.2rem;
        line-height: 1.5rem;
        margin-top: 2rem;
    }
    .newEventHeader {
        font-size: 1rem;
    }
    .newEventHeader, .newEventTxt {
        line-height: 1rem;
    }
    .newEventImg {
        min-width: 100%;
        min-height: 15rem;
    }
    .greenBgPic {
        min-width: 50%;
        min-height: 15rem;
    }
    .eventHeaderTxt {
        font-size: 1.5rem;
        line-height: 2rem;
    }
    .eventHeaderBody {
        min-height: 75%;
        font-size: 1.2rem;
        line-height: 1.5rem;
    }
    .donateCardBody {
        font-size: 1rem;
        line-height: 1rem;
    }
    .greenBgPic {
        min-height: 20rem;
    }
    .greenBgTxt {
        font-size: 1.2rem;
        line-height: 1.4rem;
    }
    .greenBgHeader {
        font-size: 1.5rem;
        line-height: 1.6rem;
    }
    .gradientTxt {
        font-size: 1.2rem;
        min-width: 6.25rem;
        line-height: 1.4rem;
    }
    .greenBg, .greenBg1, .mosaicBg, .mosaicBg1, .homeHeroSection, .greenGradientSection {
        padding: 3.125rem;
    }
}
@media (min-width: 90rem) {
    .homeHeroSection {
        padding: 20px;
    }
    .homeHeroHeader {
        font-size: 5rem;
    }
    .homeHeroText {
        font-size: 1.2rem;
        margin-bottom: 1rem;
    }
    .homeHeroBtn {
        font-size: 1.2rem;
    }
    .gradientTxt {
        min-width: 6.25rem;
        font-size: 2rem;
        line-height: 2rem;
        margin-bottom: 1rem;
    }
    .gradientTxt1 {
        font-size: 2rem;
    }
    .subscribeCard, .donateCard {
        max-width: 75%;
        min-width: 70%;
        padding: 6.25rem;
    }
    .body1 {
        font-size: 1.2rem;
        line-height: 1.5rem;
        margin-top: 2rem;
    }
    .newEventHeader {
        font-size: 1rem;
    }
    .newEventHeader, .newEventTxt {
        line-height: 1.5rem;
        font-size: 1.5rem;
    }
    .newEventImg {
        min-width: 100%;
        min-height: 30rem;
    }
    .greenBgPic {
        min-width: 50%;
        min-height: 30rem;
    }
    .eventHeaderTxt {
        font-size: 2.5rem;
        line-height: 3rem;
    }
    .eventHeaderBody {
        min-height: 75%;
        font-size: 2rem;
        line-height: 2.5rem;
    }
    .eventSectionHeader {
        font-size: 2.5rem !important;
        margin-bottom: 5rem !important;
    }
    .eventHeader1 {
        font-size: 3rem;
        line-height: 3.5rem;
        margin-top: 5rem !important;
        margin-bottom: 10rem !important;
    }
    .donateCardHeader {
        font-size: 2rem;
        padding-bottom: .5rem;
    }
    .donateCardBody {
        font-size: 1rem;
        line-height: 1rem;
    }
    .donateCardBtn {
        font-size: 1.5rem;
    }
    .input {
        font-size: 1.5rem;
    }
    .header1 {
        font-size: 2rem;
        padding-bottom: .5rem;
    }
    .greenBgPic {
        min-height: 20rem;
    }
    .greenBgTxt {
        font-size: 2rem;
        line-height: 2.4rem;
    }
    .greenBgHeader {
        font-size: 2.5rem;
        line-height: 2.6rem;
        margin-bottom: 5rem !important;
    }
    .greenBg, .greenBg1, .mosaicBg, .mosaicBg1, .homeHeroSection, .greenGradientSection {
        padding: 6.25rem !important;
    }
    .OFDesc {
        font-size: 1.5rem;
    }
    .remgap2 {
        gap: 2rem;
    }
}