@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

.footer {
    background-color: #000;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: auto;
    max-width: 100% !important;
}
.footerLinkBox {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
}
.footerLinkCol {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-right: 20px;
    border-right: 1px solid #fff;
}
.footerLinkCol1 {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 20px;
}
.footerLinkHeader {
    font-family: "Raleway", sans-serif;
    font-optical-sizing: auto;
    font-size: clamp(12px, 2.5vw, 24px);
    font-weight: 600;
    font-style: normal;
    color: #fff;
    padding-bottom: 2px;
    border-bottom: 2px solid #4EE44E;
    margin-bottom: 2px;
}
.footerLink {
    font-family: "Open Sans", sans-serif;
    font-optical-sizing: auto;
    font-size: clamp(10px, 2.5vw, 20px);
    font-weight: 400;
    font-style: normal;
    color: #fff;
    margin-bottom: 2px;
    text-wrap: wrap;
    text-align: start;
}
.footerBrand {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 1px solid #fff;
    width: 75%;
    margin-bottom: 5px;
}
.footerLogo {
    max-width: 50px;
    margin-bottom: 5px;
}
.footerBrandname {
    font-family: "Raleway", sans-serif;
    font-optical-sizing: auto;
    font-size: clamp(15px, 2.5vw, 30px);
    font-weight: 600;
    font-style: normal;
    color: #fff;
    margin-bottom: 5px;
}
.green {
    color: #4EE44E;
}
.peach {
    color: #EE9090;
}
.copyright {
    font-family: "Open Sans", sans-serif;
    font-optical-sizing: auto;
    font-size: clamp(8px, 2.5vw, 16px);
    font-weight: 300;
    font-style: normal;
    color: #fff;
}