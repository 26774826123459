.pageHeader {
    margin-bottom: 1.25rem;
}
.volunteerImg {
    border: .0625rem solid #000;
    border-radius: 0.625rem;
    width: 100%;
    max-height: 9.375rem;
    object-fit: cover;
    margin-bottom: 1.25rem;
}
.founder1Header {
    padding: .0625rem .4375rem;
    font-size: .8125rem;
}
.bodyBold {
    font-size: .6875rem;
    font-weight: 600;
    width: fit-content;
}
.founder1Body {
    text-wrap: wrap;
}
.volunteerCard {
    background-image: url('../../Images/cardbg.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 1.875rem 3.125rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: .0625rem solid #000;
    border-radius: 1.5625rem;
    width: 100%;
}
.unitImg {
    border: .0625rem solid #000;
    border-radius: .3125rem;
    max-width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
}
.unitBody, .unitFoot, .unitHead {
    margin: 0px;
    color: #fff;
}
.unitHead {
    font-size: .9375rem;
    font-weight: 600;
    text-transform: uppercase;
}
.unitBody {
    font-size: .625rem;
    font-weight: 300;
    text-align: justify;
}
.unitFoot {
    font-size: .75rem;
    font-weight: 400;
}

@media (min-width: 27rem) {
    .eventsPage {
        padding: 3rem;
    }
    .volunteerImg {
        max-height: 20rem;
    }
    .bodyBold {
        font-size: .8rem;
    }
    .unitHead {
        font-size: 2rem;
    }
    .unitBody {
        font-size: 1rem;
    }
    .unitFoot {
        font-size: 1.5rem;
    }
    .unitImg {
        max-width: 80%;
    }
}
@media (min-width: 64rem) {
    .eventsPage {
        padding: 4rem;
    }
    .volunteerImg {
        max-height: 25rem;
    }
    .bodyBold {
        font-size: 1.2rem;
    }
    .unitHead {
        font-size: 3rem;
    }
    .unitBody {
        font-size: 1.2rem;
    }
    .unitFoot {
        font-size: 2rem;
    }
    .unitImg {
        max-width: 80%;
    }
}
@media (min-width: 90rem) {
    .eventsPage {
        padding: 6rem;
    }
    .volunteerImg {
        max-height: 40rem;
    }
    .bodyBold {
        font-size: 1.5rem;
    }
    .unitHead {
        font-size: 3rem;
    }
    .unitBody {
        font-size: 1.5rem;
    }
    .unitFoot {
        font-size: 2rem;
    }
    .unitImg {
        max-width: 80%;
    }
    .volunteerCard {
        padding: 5rem;
        border: .5rem solid #000;
    }
    .inputField::placeholder {
        font-size: 1.5rem;
    }
}