.team {
    background-color: #9090EE !important;
    display: flex;
    padding: 50px 30px;
    flex-direction: column;
    align-items: center;
}
.shadow1 {
    text-shadow: 0rem .25rem .25rem rgba(0, 0, 0, 0.25);
}
.hero {
    background-image: url('../../Images/teamHero.png') !important;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 45rem;
    max-width: 100%;
}
.heroHeader {
    font-size: 5rem;
    font-weight: 700;
    text-align: center;
    color: #fff;
}
.greenBg1 {
    background-image: url('../../Images/greenBg1.png');
    max-width: 100%;
    padding: 3.125rem 2.8125rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.founder1Img {
    border: 0.125rem solid #EE9090;
    border-radius: 1.25rem;
    margin-bottom: 0.625rem;
    width: 100%;
}
.founder1Txt {
    font-size: 3rem;
    font-weight: 400;
}
.founder1Header {
    font-weight: 400;
    margin-top: 0.3125rem;
    font-size: 2rem;
    border-bottom: 0.0625rem solid #000;
    padding: 0.125rem 0.375rem;
    width: fit-content;
}
.bold {
    font-weight: 600;
    font-size: 3.5rem;
}
.founder1Body {
    font-size: 1.2rem;
    margin-top: 0.625rem;
    font-weight: 400;
    text-align: justify;
}
.bold1 {
    font-size: 2.2rem;
    font-weight: 700;
}
.teamBody {
    color: #fff;
    font-size: 2rem;
    font-weight: 300;
}
.teamImg {
    border: .0625rem solid #000;
    border-radius: .625rem;
    margin-bottom: .625rem;
    box-shadow: 0px 0px 1.25rem 0px #0000008C;
    width: 20rem;
    height: 25rem;
    max-width: 100%;

}
.greenBg1, .team {
    padding: 6.25rem;
}
.teamBody {
    font-size: 1.5rem;
}
.sectionHeader {
    font-size: 3rem;
}
.sectionHeader {
    font-size: 1.25rem;
    font-weight: 300;
    padding: .3125rem .8125rem;
    border-bottom: .0625rem solid #000;
    margin-bottom: 2.8125rem;
}

@media (max-width: 64rem) {
    .hero {
        min-height: 40rem;
    }
    .heroHeader {
        font-size: 4rem;
    }
    .teamImg {
        width: 12rem;
        height: 17rem;
        object-fit: cover;
    }
    .greenBg1, .team {
        padding: 5rem;
    }
    .founder1Img {
        width: 100%;
    }
    .founder1Header {
        font-size: 1.5rem !important;
    }
    .bold {
        font-size: 2.5rem;
    }
    .bold1 {
        font-size: 1.5rem;
    }
    .founder1Body {
        font-size: 1rem;
    }
    .founder1Txt {
        font-size: 3rem;
    }
    .teamBody {
        font-size: 1rem;
    }
    .sectionHeader {
        font-size: 2rem;
    }
    .hideOnMobile {
        display: none;
      }
}
@media (max-width: 27rem) {
    .hero {
        min-height: 25rem;
    }
    .heroHeader {
        font-size: 3rem;
    }
    .teamImg {
        width: 100%;
        height: auto;
        object-fit: cover;
    }
    .greenBg1, .team {
        padding: 3rem;
    }
    .founder1Img {
        width: 100%;
    }
    .founder1Header {
        font-size: 1rem !important;
    }
    .bold {
        font-size: 2rem;
    }
    .bold1 {
        font-size: 1rem;
    }
    .founder1Body {
        font-size: .7rem;
    }
    .founder1Txt {
        font-size: 2rem;
    }
    .teamBody {
        font-size: .7rem;
    }
    .sectionHeader {
        font-size: 1.5rem;
    }
}