.publicationImg {
    border: 1px solid #000;
    border-radius: 10px;
    width: 100%;
}
.publicationBody, .publicationFooter, .publicationHeader {
    margin: 0px;
}
.publicationHeader {
    font-size: clamp(8px, 10px, 12px);
    font-weight: 500;
    text-decoration: underline;
    text-align: start;
}
.publicationBody {
    font-size: clamp(8px, 10px, 12px);
    font-weight: 400;
    text-align: justify;
    overflow: hidden;
}
.publicationFooter {
    font-size: clamp(8px, 12px, 16px);
    font-weight: 500;
    text-decoration: underline;
    text-align: start;
}
.pageHeader {
    text-transform: uppercase;
    margin-bottom: 40px;
    font-weight: 700 !important;
}
.vlogs {
    background-color: #EE9090 !important;
    display: flex;
    padding: 50px 30px;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
}
.vlogBody {
    font-size: clamp(8px, 10px, 12px);
    font-weight: 500;
    text-align: center;
}
.vlogImg {
    border: .5px solid #000;
    border-radius: 10px;
    margin-bottom: 10px;
    box-shadow: 0px 0px 20px 0px #0000008C;
    max-width: 90px;
}
.yt {
    color: #fff !important;
  }
.flex1 {
    flex: 1;
}
.z1 {
    z-index: 1;
}
.z2 {
    z-index: 2;
}
.z3 {
    z-index: 3;
}

@media (min-width: 27rem) {
    .vlogs, .origins {
        padding: 3rem;
    }
    .publicationHeader {
        font-size: 1.2rem;
    }
    .publicationBody {
        font-size: 1rem;
    }
    .publicationFooter {
        font-size: .8rem;
    }
}
@media (min-width: 64rem) {
    .vlogs, .origins {
        padding: 4.5rem;
    }
    .publicationHeader {
        font-size: 1.5rem;
    }
    .publicationBody {
        font-size: 1.2rem;
    }
    .publicationFooter {
        font-size: 1rem;
    }
}
@media (min-width: 90rem) {
    .vlogs, .origins {
        padding: 6rem;
    }
    .publicationHeader {
        font-size: 2rem;
    }
    .publicationBody {
        font-size: 1.5rem;
    }
    .publicationFooter {
        font-size: 1.2rem;
    }
}