.App {
  text-align: center;
}

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex-grow: 1;
  padding-top: 150px;
}

@media (max-width: 900px) {
  .content {
    padding-top: 60px;
  }
}