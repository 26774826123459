.contactContainer {
    background-image: url('../../Images/contactBg.png');
    background-repeat: no-repeat;
    background-size: cover;
    max-width: 100%;
    padding: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.pageHeader {
    font-size: clamp(18px, 20px, 40px);
    font-weight: 600;
    color: black;
    text-align: start;
    width: fit-content;
    padding-right: 10px;
    padding-bottom: 2px;
    border-bottom: 2px solid #000;
    margin-bottom: 2.5rem;
}
.colHeader, .colBody {
    color: black;
    text-align: start;
    width: fit-content;
}
.colHeader {
    font-size: clamp(10px, 12px, 20px);
    font-weight: 500;
}
.colBody {
    font-size: clamp(8px, 10px, 16px);
    font-weight: 400;
}
.contactCard {
    padding: 3rem;
    margin-top: 50px;
    border: 2px solid #000;
    border-radius: 25px;
    background-color: #EE9090;
    min-width: fit-content;
    width: 80%;
    max-width: 100%;
}
.contactCardBtn {
    width: fit-content;
    padding: 7px 22px;
    background-color: #fff;
    color: #000;
    font-size: clamp(8px, 2.5vw, 16px);
    border-radius: 5px; 
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}
.inputField {
    background-color: #fff;
    border: 1px solid #000;
    border-radius: 0px 10px 10px 10px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}
.inputField::placeholder {
    font-family: "Raleway", sans-serif;
    font-optical-sizing: auto;
    font-size: clamp(8px, 10px, 16px);
    font-weight: 400;
}

@media (min-width: 27rem) {
    .pageHeader {
        font-size: 2rem;
    }
    .colHeader {
        font-size: 1.5rem;
    }
    .colBody {
        font-size: 1rem;
    }
    .contactContainer, .contactCard {
        padding: 4rem;
    }
}
@media (min-width: 64rem) {
    .pageHeader {
        font-size: 2.5rem;
    }
    .colHeader {
        font-size: 2rem;
    }
    .colBody {
        font-size: 1.2rem;
    }
    .contactContainer, .contactCard {
        padding: 5rem;
    }
}
@media (min-width: 90rem) {
    .pageHeader {
        font-size: 3rem;
    }
    .colHeader {
        font-size: 2.2rem;
    }
    .colBody {
        font-size: 1.2rem;
    }
    .contactContainer, .contactCard {
        padding: 6rem;
    }
}