.w100 {
    width: 100%;
}
.eventsPage {
    background-image: url('../../Images/mosaic-bg.png');
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    overflow: hidden;
}
.pageHeader {
    border-bottom: 0px !important;
}
.accordion {
    width: 100%;
}
.eventGroup {
    width: 100%;
    margin-bottom: 6.25rem;
}
.eventGroupHeaderTxt {
    font-size: 1.5rem;
    width: fit-content;
    text-wrap: nowrap;
    font-weight: 500;
    text-transform: uppercase;
}
.line {
    border: 0;
    height: 0.0625rem;
    background: #000;
    width: 100%;
    opacity: 1;
}
.eventGroupHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    gap: 10px;
    margin-bottom: 3.125rem;
}
.eventImg {
    max-width: 100%;
    max-height: 300px;
    object-fit: cover;
    border-radius: 20px;
    margin-bottom: 1rem;
}
.eventHeader {
    border: 0px !important;
    margin-bottom: .5rem;
    padding-left: 20px;
}
.eventBody {
    font-size: clamp(10px, 12px, 15px);
    font-weight: 400;
    padding-left: 25px;
    text-align: start;
}
.eventTab {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}


@media (width < 37.5rem) {
    .eventsPage {
        padding: 50px 20px;
    }
    .eventGroupHeaderTxt {
        font-size: 1.125rem;
    }
    .eventTab {
        flex-direction: column;
    }
}