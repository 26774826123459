@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

.openSans {
    font-family: "Open Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: lighter;
}

.idHeader {
    font-size: clamp(10px, 15px, 30px);
    text-align: start;
}
.idHeaderTxt {
    font-size: clamp(8px, 10px, 20px);
    text-align: start;
}
.idBody {
    font-size: clamp(8px, 15px, 20px);
    text-align: justify;
}
.idFooter {
    font-size: clamp(8px, 12px, 24px);
    text-align: end;
}
.slider {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    overflow: scroll;
    width: 100%;
    height: 100%;
    padding: 6rem;
}
.itemActive{
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    border: 2px solid #000;
    border-radius: 30px;
    box-shadow: 0px 0px 10px 0px #0000008C;
}
.itemActive {
    align-self: center;
    padding: 30px;
    z-index: 2;
}

.idTop {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.idCol {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: fit-content;
}
.itemImg{
    border-radius: 50%;
    width: 50px;
    height: 50px;
    object-fit: cover;
    box-shadow: 0px 2px 50px 0px #0000008C;
}


@media (max-width: 26.9rem) {
    .slider {
        padding: 3rem;
        gap: 3rem;
    }
    .itemActive{
        padding: 2rem;
        min-width: 20rem;
        min-height: 20rem;
    }
    .itemImg {
        width: 4rem;
        height: 4rem;
        box-shadow: 0px 5px 15px 0px #0000008C;
    }
    .idHeader {
        font-size: 1.2rem;
    }
    .idHeaderTxt {
        font-size: .7rem;
    }
    .idBody {
        font-size: .8rem;
    }
    .idFooter {
        font-size: .8rem;
    }
}
@media (min-width: 27rem) {
    .slider {
        padding: 3rem;
        gap: 3rem;
    }
    .itemActive{
        padding: 3rem;
        min-width: 40rem;
        min-height: 25rem;
    }
    .itemImg {
        width: 5rem;
        height: 5rem;
    }
    .idHeader {
        font-size: 2rem;
    }
    .idHeaderTxt {
        font-size: 1.5rem;
    }
    .idBody {
        font-size: 1.2rem;
    }
    .idFooter {
        font-size: 1rem;
    }
}
@media (min-width: 64rem) {
    .slider {
        gap: 5rem;
    }
    .itemActive{
        padding: 4rem;
        min-width: 50rem;
        min-height: 30rem;
    }
    .itemImg {
        width: 7rem;
        height: 7rem;
    }
    .idHeader {
        font-size: 2rem;
    }
    .idHeaderTxt {
        font-size: 1.5rem;
    }
    .idBody {
        font-size: 1.2rem;
    }
    .idFooter {
        font-size: 1rem;
    }
}
@media (min-width: 90rem) {
    .slider {
        gap: 5rem;
    }
    .itemActive{
        padding: 4rem;
        min-width: 50rem;
        min-height: 30rem;
    }
    .itemImg {
        width: 7rem;
        height: 7rem;
    }
    .idHeader {
        font-size: 2rem;
    }
    .idHeaderTxt {
        font-size: 1.5rem;
    }
    .idBody {
        font-size: 1.2rem;
    }
    .idFooter {
        font-size: 1rem;
    }
}